var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "backend-carrier-autocomplete" },
    [
      _c("v-autocomplete", {
        attrs: {
          items: _vm.carrierList,
          loading: _vm.loading,
          "search-input": _vm.search,
          color: "info",
          clearable: "",
          "item-text": "name",
          "item-value": "id",
          label: "Carrier",
          "return-object": "",
          required: "",
          "close-on-content-click": "",
        },
        on: {
          "update:searchInput": function ($event) {
            _vm.search = $event
          },
          "update:search-input": function ($event) {
            _vm.search = $event
          },
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.fireEnterEvent()
          },
          blur: function ($event) {
            return _vm.pushCarrier()
          },
          "click:clear": function ($event) {
            return _vm.removeCarrier()
          },
        },
        model: {
          value: _vm.carrier,
          callback: function ($$v) {
            _vm.carrier = $$v
          },
          expression: "carrier",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }